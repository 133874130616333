import React from "react"
import { Canvas } from '@react-three/fiber'
import { Suspense } from 'react'
import Model from "../components/Three/model"
import { Helmet } from "react-helmet"

export default () => {
  const window_width = typeof window !== "undefined" ? window.innerWidth : 0;
  const window_height = typeof window !== "undefined" ? window.innerHeight : 0;

  let calc_zoom = ()=> {
    if(window_width < 1400) {
      return 1.25;
    } else if(window_width < 1200) {
      return 1;
    } else if(window_width < 992) {
      return .75;
    }  else if(window_width < 600) {
      return .05;
    }

    return 1.5;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blokhaus - 404</title>
      </Helmet>
      <Canvas id={'canvas-404'} style={{ background: "black" }} camera={{ aspect: window_width / window_height , zoom: calc_zoom(), position: [-10, 40, 40] }}>
        <Suspense fallback={null}>
          <Model path={'/rendering/404.gltf'} />
        </Suspense>
      </Canvas>
    </>
  )
}
